import {
  HTTP_INTERCEPTORS,
  HttpClient,
  HttpClientModule,
} from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { OverlayContainer } from '@angular/cdk/overlay';
import { GoogleMapsModule } from '@angular/google-maps';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { EmbedVideo } from 'ngx-embed-video';
import { InputFileConfig, InputFileModule } from 'ngx-input-file';
import { NgProgressModule } from 'ngx-progressbar';
import { NgProgressHttpModule } from 'ngx-progressbar/http';
import { environment } from 'src/environments/environment';
import { CustomOverlayContainer } from './theme/utils/custom-overlay-container';

//primeng
import { AutoCompleteModule } from 'primeng/autocomplete';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { CarouselModule } from 'primeng/carousel';
import { DropdownModule } from 'primeng/dropdown';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputTextModule } from 'primeng/inputtext';
import { MenubarModule } from 'primeng/menubar';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { ScrollerModule } from 'primeng/scroller';
import { SelectButtonModule } from 'primeng/selectbutton';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app.routing';
import { AppSettings } from './app.settings';
import { GiveawayModule } from './features/giveaway/giveaway.module';
import { SharedModule } from './shared/shared.module';

import {
  MAT_DATE_LOCALE,
  MatNativeDateModule,
  MatRippleModule,
} from '@angular/material/core';
import { MatLegacyPaginatorModule as MatPaginatorModule } from '@angular/material/legacy-paginator';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { MatSortModule } from '@angular/material/sort';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NotFoundComponent } from './features/not-found/not-found.component';
import { JwtTokenService } from './shared/_services/jwtToken.service';
import { LocalStorageService } from './shared/_services/localStorage.service';
import { SessionStorageService } from './shared/_services/sessionStorage.service';
import { UniversalAppInterceptor } from './shared/_services/universalAppInterceptor.service';
import { LangComponent } from './theme/components/lang/lang.component';

import { NgxMatMomentModule } from '@angular-material-components/moment-adapter';
import { MatSelectCountryModule } from '@angular-material-extensions/select-country';
import { CommonModule } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { RouterModule } from '@angular/router';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { NgxQRCodeModule } from '@techiediaries/ngx-qrcode';
import { VimeModule } from '@vime/angular';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { FooterNewComponent } from './components/footer/footer.component';
import { PopupDialogComponent } from './components/popup-dialog/popup-dialog.component';
import { UserNotificationsComponent } from './components/user-notifications/user-notifications.component';
import { WebSocketAPI } from './shared/_services/web-socket.service';
import { ToastComponent } from './theme/components/toast/toast.component';
import { ToasterComponent } from './theme/components/toaster/toaster.component';

import { MatSnackBarModule } from '@angular/material/snack-bar';
import {
  MAT_TOOLTIP_DEFAULT_OPTIONS,
  MatTooltipDefaultOptions,
} from '@angular/material/tooltip';
import { CachingInterceptor } from '@shared/interceptors/caching.interceptor';
import { HttpEncryptionInterceptor } from '@shared/interceptors/http-encryption.interceptor';
import { AuthModule } from './features/auth/auth.module';

const config: InputFileConfig = {
  fileAccept: '*',
};

export const OtherOptions: MatTooltipDefaultOptions = {
  showDelay: 0,
  hideDelay: 0,
  touchendHideDelay: 0,
  disableTooltipInteractivity: true,
};

export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(
    httpClient,
    environment.url + '/assets/i18n/',
    '.json'
  );
}

@NgModule({
  declarations: [
    AppComponent,
    NotFoundComponent,
    LangComponent,
    ToastComponent,
    ToasterComponent,
    PopupDialogComponent,
    UserNotificationsComponent,
  ],
  imports: [
    CommonModule,
    AuthModule,
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    EmbedVideo.forRoot(),
    NgProgressModule,
    NgProgressHttpModule,
    InputFileModule.forRoot(config),
    AppRoutingModule,
    SharedModule,
    NgbModule,
    VimeModule,
    MatNativeDateModule,
    MatRippleModule,
    GiveawayModule,
    MatSortModule,
    MatPaginatorModule,
    MatTableModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatSelectCountryModule.forRoot('en'),
    AngularEditorModule,
    FormsModule,
    RouterModule,
    MatDialogModule,
    BrowserAnimationsModule,
    NgxQRCodeModule,
    NgxMatMomentModule,
    LazyLoadImageModule,
    MatCardModule,
    ButtonModule,
    InputTextModule,
    AutoCompleteModule,
    CalendarModule,
    DropdownModule,
    InputNumberModule,
    GoogleMapsModule,
    SelectButtonModule,
    OverlayPanelModule,
    MenubarModule,
    CarouselModule,
    FooterNewComponent,
    ScrollerModule,
    MatSnackBarModule,
  ],
  providers: [
    AppSettings,
    WebSocketAPI,
    { provide: OverlayContainer, useClass: CustomOverlayContainer },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: UniversalAppInterceptor,
      multi: true,
    },
    { provide: HTTP_INTERCEPTORS, useClass: CachingInterceptor, multi: true },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpEncryptionInterceptor,
      multi: true,
    },
    { provide: LocalStorageService, useClass: LocalStorageService },
    { provide: SessionStorageService, useClass: SessionStorageService },
    { provide: JwtTokenService, useClass: JwtTokenService },
    { provide: MAT_DATE_LOCALE, useValue: 'ro-RO' },
    { provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: OtherOptions },
  ],
  exports: [TranslateModule],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
