import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule, Routes } from '@angular/router';
import { VimeModule } from '@vime/angular';
import { SocialLoginModule } from 'angularx-social-login';
import { SharedModule } from '../../shared/shared.module';
import { ForgottenPassComponent } from './forgotten-pass/forgotten-pass.component';
import { GoogleCallbackSessionComponent } from './google-callback-session/google-callback-session.component';
import { LoginEnrollmentComponent } from './login/login-enrollment/login-enrollment.component';
import { LoginComponent } from './login/login.component';
import { ProviderRegisterComponent } from './provider-register/provider-register.component';
import { RegisterComponent } from './register/register.component';

export const routes: Routes = [
  {
    path: 'login/google/callback',
    component: GoogleCallbackSessionComponent,
    pathMatch: 'full',
  },
];

@NgModule({
  declarations: [
    LoginComponent,
    GoogleCallbackSessionComponent,
    ForgottenPassComponent,
    RegisterComponent,
    ProviderRegisterComponent,
    LoginEnrollmentComponent,
  ],
  exports: [LoginEnrollmentComponent],
  imports: [
    CommonModule,
    SharedModule,
    VimeModule,
    MatIconModule,
    MatDialogModule,
    SocialLoginModule,
    RouterModule.forChild(routes),
  ],
  providers: [MatDialogModule],
})
export class AuthModule {}
