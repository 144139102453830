import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ResetUserPasswordOutput } from '@shared/_models/user.model';
import {
  BehaviorSubject,
  catchError,
  lastValueFrom,
  Observable,
  of,
  tap,
} from 'rxjs';
import { map } from 'rxjs/operators';
import { UserService } from './userData.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private readonly JWT_TOKEN = 'token';
  private readonly REFRESH_TOKEN = 'refreshToken';

  constructor(
    public httpClient: HttpClient,
    private userService: UserService
  ) {}

  private twoFA = new BehaviorSubject(false);
  twoFAObs = this.twoFA.asObservable();

  trigger2FA(message: boolean) {
    this.twoFA.next(message);
  }

  login(data): Observable<boolean> {
    return this.httpClient.post('/bestinform/public/login', data).pipe(
      tap((tokens: any) => this.doLoginUser(data.email, tokens)),
      map((res) => {
        console.log('login service', res);
        return res;
      })
    );
  }

  tokens(data): Observable<boolean> {
    console.log('Tokens sessionid data', data);
    return this.httpClient.get('/bestinform/tokens/' + data).pipe(
      tap((tokens: any) => {
        if (tokens.success) {
          this.storeTokens(tokens);
        }
      }),
      map((res) => {
        console.log('Tokens returned', res);
        return res;
      })
    );
  }

  login2FA(authenticationCode) {
    return this.httpClient
      .post(
        '/bestinform/public/login2FA?authenticationCode=' + authenticationCode,
        {}
      )
      .pipe(
        tap((tokens: any) => this.doLoginUser(authenticationCode, tokens)),
        map((res) => {
          console.log('2fa login service', res);
          return res;
        }),
        catchError((error) => {
          console.log('2fa error login service', error);
          return of(false);
        })
      );
  }

  sendAuthenticationCode(dataObj: object) {
    return this.httpClient.post(
      '/bestinform/public/sendAuthenticationCode',
      dataObj
    );
  }

  sendUserInvalidSessionEmail(email: string) {
    return this.httpClient.get(
      '/bestinform/sendUserInvalidSessionEmail?email=' + email
    );
  }

  private doLoginUser(username?: string, tokens?) {
    this.storeTokens(tokens);
  }

  private storeTokens(tokens) {
    console.log('store tokens', tokens);
    localStorage.setItem(this.JWT_TOKEN, tokens.token);
    localStorage.setItem(this.REFRESH_TOKEN, tokens.refreshToken);
  }

  refreshToken() {
    return this.httpClient
      .post(
        '/bestinform/refreshToken?refreshToken=' + this.getRefreshToken(),
        {}
      )
      .pipe(
        tap((tokens) => {
          this.storeTokens(tokens);
        }),
        catchError((error) => {
          console.log('error refresh token');
          this.userService.logout();
          return of(false);
        })
      );
  }

  private getRefreshToken() {
    return localStorage.getItem(this.REFRESH_TOKEN);
  }

  getJWTToken() {
    return localStorage.getItem(this.JWT_TOKEN);
  }

  register(user: object) {
    return this.httpClient.post('/bestinform/public/registerUser', user);
  }

  sendRegistrationEmail(id: string) {
    return this.httpClient.get(
      '/bestinform/public/sendRegistrationEmail?userId=' + id
    );
  }

  sendResetUserPasswordCode(email: string): Promise<ResetUserPasswordOutput> {
    return lastValueFrom(
      this.httpClient.get<ResetUserPasswordOutput>(
        '/bestinform/public/sendResetUserPassword?email=' + email
      )
    );
  }

  resetPassword(password: object): Promise<ResetUserPasswordOutput> {
    return lastValueFrom(
      this.httpClient.post<ResetUserPasswordOutput>(
        '/bestinform/public/resetNewPassword',
        password
      )
    );
  }

  loginWithFacebook(role?: string, email?: string, token?: string) {
    return this.httpClient
      .post('/bestinform/public/loginWithFacebook?role=client', {
        accessToken: token,
      })
      .pipe(
        tap((tokens: any) => this.doLoginUser(email, tokens)),
        map((res) => {
          console.log('login service social', res);
          return res;
        })
      );
  }

  loginWithGoogle(idToken, email) {
    return this.httpClient
      .post('/bestinform/public/login-google', idToken)
      .pipe(
        tap((tokens: any) => this.doLoginUser(email, tokens)),
        map((res) => {
          console.log('login service', res);
          return res;
        })
      );
  }
}
