import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { DesignResponsiveService } from '@shared/_services/design-responsive.service';
import { RegulationCardComponent } from '@standalone-components/regulation-card/regulation-card.component';

@Component({
  selector: 'app-footer-new',
  standalone: true,
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  imports: [RegulationCardComponent, CommonModule],
})
export class FooterNewComponent {
  @Input() isHomepage: boolean = false;

  isMobile: boolean = this.designResponsiveService.isMobile;

  constructor(
    private readonly designResponsiveService: DesignResponsiveService
  ) {}
}
