import { Component, OnInit } from '@angular/core';
import { ToastService } from '../../../shared/_services/toast.service';

@Component({
  selector: 'app-toaster',
  templateUrl: './toaster.component.html',
  styleUrls: ['./toaster.component.scss'],
})
export class ToasterComponent implements OnInit {
  currentToasts: Array<object> = [];

  constructor(private readonly toastService: ToastService) {}

  ngOnInit() {
    this.subscribeToToasts();
  }

  subscribeToToasts() {
    this.toastService.toastEvents.subscribe((toasts: object) => {
      const currentToast = {
        type: toasts['type'],
        title: toasts['title'],
        message: toasts['message'],
        hidden: toasts['hidden'],
      };
      this.currentToasts.push(currentToast);
    });
  }

  dispose(index: number) {
    if (index < this.currentToasts.length) {
      this.currentToasts.splice(index, 1);
    }
  }
}
